.animatecar{
    animation: animate 3s linear alternate-reverse infinite;
}

@media screen and (min-width: 640px) {
    .animatecar{
        animation: animate2 3s linear alternate-reverse infinite;
    }
  }

  @media screen and (min-width: 1280px) {
    .animatecar{
        animation: animate3 3s linear alternate-reverse infinite;
    }
  }

  @media screen and (min-width: 1536px) {
    .animatecar{
        animation: animate4 3s linear alternate-reverse infinite;
    }
  }

@keyframes animate {
    from {left: 0rem;}
    to {left: 14rem;}
  }

@keyframes animate2 {
    from {left: 0rem;}
    to {left: 28rem;}
}

@keyframes animate3 {
    from {left: 0rem;}
    to {left: 52rem;}
}

@keyframes animate4 {
    from {left: 0rem;}
    to {left: 62rem;}
}


.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}